import React, { useContext } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { SpinnerContext } from '../context/SpinnerContext';

const Spinner = () => {
  const { spinnerLoading } = useContext(SpinnerContext);
  
  return (
    <>
      {spinnerLoading.isLoading && (
        <div className="spinner-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          <h3 className='spinner-text'>{spinnerLoading.text}</h3>
        </div>
      )}
    </>
  );
};

export default Spinner;
