import React, { useState } from 'react';
import { Steps } from 'antd';
import useManifestWizard from "../hooks/useManifestWizard";

// object data parameters
// https://www.codexworld.com/embed-pdf-document-file-in-html-web-page/
var steps = [
  {
    title: 'Manifiesto',
    data: "https://lunasoft.net/wp-content/uploads/2024/01/Manifiesto_Luna_Soft.pdf#zoom=75&navpanes=0&statusbar=0&toolbar=0",
    type: "application/pdf",
  },
  {
    title: 'Acuerdo de Servicio',
    data: 'https://lunasoft.net/wp-content/uploads/2023/12/terminos_y_condiciones_manfiesto.pdf#zoom=75&navpanes=0&statusbar=0&toolbar=0',
    type: "application/pdf",
  },
  {
    title: 'Aviso de Privacidad',
    data: 'https://lunasoft.net/wp-content/uploads/2023/12/Aviso_de_Privacidad_LUNASOFT.pdf#zoom=75&navpanes=0&statusbar=0&toolbar=0',
    type: "application/pdf",
  },
];

const ManifestWizard = (props) => {
  const { wizardStep,setCurrentWizardStep, setPreviousWizardStep } = useManifestWizard();
  const [current, setCurrent] = useState(0);

  const onChange = (value) => {
    setPreviousWizardStep(current)
    setCurrentWizardStep(value)
    setCurrent(value);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <Steps current={wizardStep} items={items}  />
      <div className="pdf-container mt-4 mb-4" style={{
        width: '100%', height: '75vh', overflow: 'auto',
      }}><object style={{ width: '100%', height: '100%', }} type={steps[wizardStep].type} data={steps[wizardStep].data} ></object>
      </div>
    </>
  );
};
export default ManifestWizard;