
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ManifestWizard from "./components/ManifestWizard"
import ManifestForm from "./components/ManifestForm"
import ManifestSuccess from "./components/ManifestSuccess";

function Manifest() {
    return (<>
        <ManifestSuccess />
        <div className='container bg-white'>
            <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
                <GoogleReCaptchaProvider reCaptchaKey="6LcMGssUAAAAAP4yS7H_BJPpaoed-sl-xXoNylVH">
                    <ManifestForm />
                </GoogleReCaptchaProvider>
                <div className="col-span-2">
                    <ManifestWizard />
                </div>
            </div>
        </div>
    </>)
}

export default Manifest;