
export const uploadManifest = ({ ...payload }, captchaToken) => {
    return fetch(`/api/public/manifests`, {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'X-Captcha': captchaToken,
        },
    });
}