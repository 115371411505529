import React, { createContext, useState } from 'react';

export const SpinnerContext = createContext();

export const SpinnerProvider = ({ children }) => {
  const [spinnerLoading, setSpinnerLoading] = useState({isLoading:false, text:''});

  return (
    <SpinnerContext.Provider value={{ spinnerLoading, setSpinnerLoading }}>
      {children}
    </SpinnerContext.Provider>
  );
};
