import React from 'react';

const PageLayout = ({ children }) => {
    return (
      <div className="min-h-screen flex items-center justify-center" style={{'backgroundColor':'#FFFFFF'}}>
        <div className="bg-white px-8 pt-1 shadow-md">
          <div className="mb-4">{children}</div>
        </div>
      </div>
    );
  };
  
export default PageLayout;