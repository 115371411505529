import { useDispatch, useSelector } from "react-redux";
import {
    setManifest, setManifestPdfData,
    setEmail, setWizardStep, setPrevWizardStep,
    setManifestFormError, setOpenManifestSuccess
} from "../slices/manifestSlice";

const useManifestWizard = () => {
    const dispatch = useDispatch();
    const email = useSelector(state => state.manifest.email);
    const wizardPrevStep = useSelector(state => state.manifest.previousWizardStep);
    const wizardStep = useSelector(state => state.manifest.wizardStep);
    const manifestText = useSelector(state => state.manifest.manifestText);
    const manifiestPdf = useSelector(state => state.manifest.manifiestPdf);
    const manifestFormHasError = useSelector(state => state.manifest.manifestFormHasError);
    const manifestSuccess = useSelector(state => state.manifest.manifestSuccess);

    const setPreviousWizardStep = (step) => {
        dispatch(setPrevWizardStep(step))
    }
    const setCurrentWizardStep = (step) => {
        dispatch(setWizardStep(step))
    }
    const nextWizardStep = () => {
        dispatch(setWizardStep(wizardStep + 1))
    }
    const prevWizardStep = () => {
        dispatch(setWizardStep(wizardStep - 1))
    }
    const setManifestTemplate = (text) => {
        dispatch(setManifest(text))
    }
    const setManifestFormHasError = (hasError) => {
        dispatch(setManifestFormError(hasError))
    }
    const setManifiestPdf = (data) => {
        dispatch(setManifestPdfData(data))
    }
    const setManifestEmail = (data) => {
        dispatch(setEmail(data))
    }
    const setOpenModalManifestSuccess = () => {
        dispatch(setOpenManifestSuccess(true))
    }

    return {
        email,
        wizardPrevStep,
        wizardStep,
        manifestText,
        manifiestPdf,
        manifestSuccess,
        manifestFormHasError,
        setManifestEmail,
        setManifiestPdf,
        setManifestTemplate,
        setManifestFormHasError,
        setOpenModalManifestSuccess,
        setPreviousWizardStep,
        setCurrentWizardStep,
        nextWizardStep,
        prevWizardStep,
    }
}

export default useManifestWizard;