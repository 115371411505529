import { KEYUTIL, KJUR } from "jsrsasign";
import forge from 'node-forge'

const BEGIN_CERTIFICATE = "-----BEGIN CERTIFICATE-----\n"
const END_CERTIFICATE = "\n-----END CERTIFICATE-----"
const BEGIN_PRIVATE_KEY = "-----BEGIN ENCRYPTED PRIVATE KEY-----\n"
const END_PRIVATE_KEY = "\n-----END ENCRYPTED PRIVATE KEY-----\n"
const b64RE = /data:.*;base64,/;

const EncodeB64 = (data) => forge.util.encode64(forge.util.encodeUtf8(data));
const DecodeB64 = (data) => forge.util.decode64(forge.util.decodeUtf8(data));

const ConvertFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => reject(error);
    })
}

const ConvertFileToText = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file)
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => reject(error);
    })
}

const GetCertificateMeta = (pem) => {
    const cert = forge.pki.certificateFromPem(pem);
    var eValue = cert.publicKey.e.toString(16),
        nValue = cert.publicKey.n.toString(16);

    const taxIdAttribute = cert.subject.attributes
        .find(attr => attr.type.trim() == "2.5.4.45");
    const companyNameAttribute = cert.subject.attributes
        .find(attr => attr.type.trim() == "2.5.4.41");
    const isCsdAttribute = !!cert.subject.attributes
        .find(attr => attr.name == "organizationalUnitName" && attr.shortName == "OU")

    return {
        exponent: eValue,
        modulus: nValue,
        taxId: taxIdAttribute.value.split("/")[0].trim(),
        companyName: companyNameAttribute.value,
        isCsd: isCsdAttribute,
        validity: cert.validity
    };
}

const ConvertFileCertToPem = async (file) => {
    var result = await ConvertFileToBase64(file)
    var pem = BEGIN_CERTIFICATE + result.split(",")[1].replace(/(.{64})/g, '$1\n') + END_CERTIFICATE;
    return pem;
}

const ConvertFileKeyToPem = async (file) => {
    var result = await ConvertFileToBase64(file)
    var pem = BEGIN_PRIVATE_KEY + result.split(b64RE)[1].replace(/(.{64})/g, '$1\n') + END_PRIVATE_KEY;
    return pem;
}

const SignWithRSAKey = function (data, keyPem, password) {
    var privateKey = KEYUTIL.getKeyFromEncryptedPKCS8PEM(keyPem, password);
    var nValue = privateKey.n.toString(16),
        eValue = privateKey.e.toString(16);

    var signer = new KJUR.crypto.Signature({ "alg": "SHA1withRSA" });
    signer.init(privateKey, password);
    var signatureHex = signer.signString(data);

    return {
        modulus: nValue,
        exponent: eValue,
        signatureHex,
        signatureB64: HexToB64(signatureHex)
    }
}

const VerifyWithRSACertificate = function (certPem, originalData, signedData) {
    var signer = new KJUR.crypto.Signature({ "alg": "SHA1withRSA" });
    signer.init(certPem);
    signer.updateString(originalData);

    return signer.verify(signedData);
}

const HexToB64 = function (hexValue) {
    var splitter = /\w{2}/g;
    var verify = /^[0-9a-fA-F]{2,}$/;
    if (!verify.test(hexValue) || hexValue.length % 2 != 0)
        throw 'No es un valor en hexadecimal';

    var letters = hexValue.match(splitter);
    var text = '';
    for (var count in letters)
        text += String.fromCharCode(parseInt(letters[count], 16));

    return window.btoa(text);
}

const HexToBytes = function (hex) {
    // Check if the hex string has an odd length
    if (hex.length % 2 !== 0) {
        throw new Error("Hex string must have an even length.");
    }

    // Use Uint8Array for browsers or Buffer for Node.js
    const arrayBuffer = new Uint8Array(hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    // For browsers
    if (typeof TextDecoder !== 'undefined') {
        return new TextDecoder().decode(arrayBuffer);
    }

    // For Node.js
    if (typeof Buffer !== 'undefined') {
        return Buffer.from(arrayBuffer).toString('utf-8');
    }

    throw new Error("Unsupported environment. Please use a browser or Node.js.");
}

// Encode Latin-1 to UTF-8
const Latin1ToUtf8 = function (latin1String) {
    // Convert Latin-1 string to array of code points
    const codePoints = latin1String.split('').map(char => char.charCodeAt(0));

    // Create a Uint8Array from the code points
    const uint8Array = new Uint8Array(codePoints);

    // Convert the Uint8Array to a UTF-8 string
    return new TextDecoder('utf-8').decode(uint8Array);
}

export {
    ConvertFileKeyToPem,
    ConvertFileCertToPem,
    ConvertFileToBase64,
    ConvertFileToText,
    EncodeB64,
    DecodeB64,
    GetCertificateMeta,
    SignWithRSAKey,
    VerifyWithRSACertificate,
    HexToB64,
    HexToBytes,
    Latin1ToUtf8,
}