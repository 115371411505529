import { createSlice } from '@reduxjs/toolkit';

var _manifestTemplate =
    "Por medio del presente, <nombre o razón social>, con RFC <rfc>, manifiesto mi conformidad y " +
    "autorización para que la empresa LUNA SOFT,S.A. DE C.V., Proveedor Autorizado de Certificación" +
    " de CFDI con número de autorización 16543, proceda a entregar al Servicio de Administración Tributaria, " +
    "copia de los comprobantes fiscales que me haya certificado, de acuerdo a lo establecido en la regla 2.7.2.7" +
    " de la Resolución Miscelánea Fiscal para 2023 publicada en el Diario Oficial de la Federación el 27 de " +
    "Diciembre de 2022. Al mismo tiempo aceptó que la empresa LUNA SOFT, S.A. DE C.V., sea quien me proporcione " +
    "a mi nombre o al de mi representada, el *Servicio de Certificación para todos los Comprobantes Fiscales " +
    "Digitales por Internet (CFDI) que emita como persona física o como persona moral. *Servicio de Certificación: " +
    "se refiere a validación y timbrado de Comprobantes Fiscales Digitales por Internet (CFDI)."

const manifestSlice = createSlice({
    name: 'manifest',
    initialState: {
        manifestText: _manifestTemplate,
        manifestFormHasError: false,
        manifiestPdf: "",
        wizardStep: 0,
        previousWizardStep: 0,
        email: "",
        manifestSuccess: false,
    },
    reducers: {
        setManifest: (state, action) => {
            state.manifestText = action.payload;
        },
        setManifestPdfData: (state, action) => {
            state.manifiestPdf = action.payload;
        },
        setPrevWizardStep: (state, action) => {
            state.previousWizardStep = action.payload;
        },
        setWizardStep: (state, action) => {
            state.wizardStep = action.payload;
        },
        setManifestFormError: (state, action) => {
            state.manifestFormHasError = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setOpenManifestSuccess: (state, action) => {
            state.manifestSuccess = action.payload;
        },
    },
});

export const { setManifest, setManifestPdfData, setEmail, setWizardStep, setPrevWizardStep, setManifestFormError, setOpenManifestSuccess } = manifestSlice.actions;
export default manifestSlice.reducer;