import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import defaultTheme from "./theme/defaultTheme"
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import { store } from './reducers/store';
import locale from "antd/lib/locale/es_ES";
import PageLayout from './layout/PageLayout';
import Footer from './layout/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={locale} theme={defaultTheme}>
        <PageLayout>
          <App />
          <Footer />
        </PageLayout>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
