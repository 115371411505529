import React from 'react';
import dayjs from "dayjs";

const Footer = () => {
    return (
        <footer className="mt-auto text-center text-gray-500">
            <div>
                Versión {process.env.REACT_APP_VERSION}<br />
                &copy;  Luna Soft S.A. de C.V. (2001-{dayjs().format("YYYY")})
            </div>
        </footer>
    );
};

export default Footer;