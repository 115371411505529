import './App.css';
import { SpinnerProvider } from './context/SpinnerContext';
import Spinner from './layout/Spinner';
import Manifest from './features/manifest'

function App() {
  return (
    <div className="App">
      <SpinnerProvider>
        <Spinner />
        <Manifest />
      </SpinnerProvider>
    </div>
  );
}

export default App;
