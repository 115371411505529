export default {
    token: {
        colorPrimary: "#3485FC",
        colorLink: "#3485FC",
        colorSuccess: "#52c41a",
        colorWarning: "#faad14",
        colorError: "#f5222d",
        fontSize: 14,
        colorText: "#1F242A",
        colorTextSecondary: "#1F242A",
        colorTextDisabled: "rgba(0,0,0,.25)",
        borderRadius: 3,
        borderColor: "#d9d9d9",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        tableRowHoverBg: "#E3EFF5",
        zIndexPopup: 1045
    }
}