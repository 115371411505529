import { combineReducers } from 'redux';
import manifest from "../features/manifest/slices/manifestSlice";

// Redux: Root Reducer
const appReducer = combineReducers({
    manifest
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;