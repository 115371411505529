import React, { useState, useEffect } from 'react';
import { Button, Modal, Alert } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import useManifestWizard from "../hooks/useManifestWizard";

const ManifestSuccess = () => {
    const { manifiestPdf, manifestSuccess, email } = useManifestWizard();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsModalOpen(manifestSuccess)
    }, [manifestSuccess])

    const handleOk = () => {
        setIsModalOpen(false);
        setTimeout(() => window.location.href = "/", 1000);
    };
    const onDownload = () => {
        var a = document.createElement("a");
        a.href = manifiestPdf;
        a.download = "manifiesto.pdf";
        a.click();
        a.remove();
        window.URL.revokeObjectURL(manifiestPdf);
    }

    return (
        <>
            <Modal title="Carta Manifiesto Firmada Correctamente" open={isModalOpen} closeIcon={null}
                footer={[<Button key="submit" type='primary' onClick={handleOk}> Aceptar</Button>]}>
                <div className="text-center">
                    <Alert description={`Se ha enviado la carta manifiesto firmada a los correos electrónicos proporcionados: ${email.split(',').join(', ')}`} type="success" />
                    <Button className='m-4' icon={<DownloadOutlined />} type="link" onClick={onDownload}>
                        Descargar Carta Manifiesto Firmada
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ManifestSuccess;